<template>
  <el-main>
    <router-link to="/article/articleAdd"><el-button style="margin-bottom: 10px" size="small" type="primary" icon="el-icon-plus">添加文章</el-button></router-link>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="文章标题：">
        <el-input size="small" placeholder="请输入文章标题" v-model="title"></el-input>
      </el-form-item>
      <el-form-item label="文章分类：">
        <el-select size="small" clearable v-model="article_classify_id" placeholder="请选择">
          <el-option v-for="item in typeList" :key="item.id" :label="item.classify_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否显示：">
        <el-select size="small" v-model="is_show" placeholder="请选择">
          <el-option v-for="item in show" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getList()">搜索</el-button>
        <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="article-list">
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="rank" fixed label="排序" width="75" align="center">
          <template #header>
            <div class="rank">
              <span>排序</span>
              <i class="el-icon-edit-outline"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <el-input v-model="scope.row.rank" @change="tableChange(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" align="center"></el-table-column>
        <el-table-column prop="picture" label="文章主图" align="center">
          <template slot-scope="scope">
            <el-image :src="scope.row.picture" style="width: 50px; height: 50px"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="文章标题" align="center"></el-table-column>
        <el-table-column prop="classify_name" label="分类名称" align="center"></el-table-column>
        <el-table-column prop="is_show" label="显示" align="center"  width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_show" :active-value="1" :inactive-value="0" @change="tableChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" align="center">
          <template slot-scope="scope">
            <span>{{ getDateformat(scope.row.update_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
            <el-button type="text" size="small" @click="inputReject(scope.row.id)">驳回</el-button>
            <el-button @click="remove(scope.row.id, scope.$index)" type="text" size="small" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="驳回" :visible.sync="dialogVisible_audit" width="30%">
      <div class="mb10">请填写驳回原因：</div>
      <el-input type="textarea" :rows="4" placeholder="请填写驳回原因" v-model="reject_form.reason"
        class="mb10"></el-input>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible_audit = false">取 消</el-button>
          <el-button type="primary" @click="reject">确 定</el-button>
      </span>
    </el-dialog>
    <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      page: 1,
      pageNum: 10,
      total: 0,
      title: '',
      article_classify_id: '',
      is_show: '',
      show: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 2,
          label: '是',
        },
        {
          value: 1,
          label: '否',
        },
      ],
      typeList: [],
      list: [],
      dialogVisible_audit: false,
      reject_form:{
        id:'',
        status:0,
        reason:''
      }
    };
  },
  created() {
    let utils = this.$store.state.utils;
    if (utils.is_record) {
      this.page = utils.page;
      this.pageNum = utils.rows;
      utils.is_record = !1;
    }
    this.getType();
    this.getList();
  },
  methods: {
    getType() {
      this.$axios
        .post(this.$api.set.listArticleClass, {
          classify_name: this.classify_name,
        })
        .then(res => {
          console.log(res);
          if (res.code == 0) {
            this.typeList = res.result.list;
          }
        });
    },
    cancelSearch() {
      this.title = '';
      this.article_classify_id = '';
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    getList() {
      this.$axios
        .post(this.$api.set.listArticle, {
          page: this.page,
          rows: this.pageNum,
          article_classify_id: this.article_classify_id,
          title: this.title,
          is_show: this.is_show,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            this.list = list;
            this.total = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    edit(row) {
      this.$router.push({
        path: '/article/articleEdit',
        query: {
          info: JSON.stringify(row),
          page: this.page,
          rows: this.pageNum,
        },
      });
    },
    remove(id, index) {
      this.$confirm('确认删除此文章？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .post(this.$api.set.delArticle, {
              id: id,
            })
            .then(res => {
              if (res.code == 0) {
                this.$message({
                  message: '删除成功',
                  type: 'success',
                });
                this.list.splice(index, 1);
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(err => {});
    },
    tableChange(row) {
      this.$axios.post(this.$api.set.editArticle, row).then(res => {
        if (res.code == 0) {
          this.getList();
          this.$message({
            message: '编辑成功',
            type: 'success',
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    inputReject(id){
      this.reject_form.id = id
      this.dialogVisible_audit = true
    },
    reject(){
      if(!this.reject_form.reason){
        return this.$message({
          message: '请填写驳回原因',
          type: 'warning',
        });
      }
      this.$axios.post(this.$api.set.rejectArticle, this.reject_form).then(res => {
        if (res.code == 0) {
          this.getList();
          this.$message({
            message: '编辑成功',
            type: 'success',
          });
          this.dialogVisible_audit = false
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.el-table {
  border-left: 1px solid #e8eef1;
  border-right: 1px solid #e8eef1;
  /deep/ .el-input__inner {
    border-color: #fff;
  }
  /deep/ .el-input__inner:focus {
    border-color: #409eff;
  }
}
.el-main {
  background: #fff;
}
.mb10{
  margin-bottom: 10px;
}
</style>
